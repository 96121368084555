import { Box, Button, Group, Text, Grid, Card } from "@mantine/core"
import styles from "./test.module.scss"

export const Test = () => {
  return (
    <Box className={styles.Test} pos="relative" style={{ paddingTop: '20px' }}>
      <Group direction="column" spacing="lg">
        <Group justify="space-between">
          <Text size="xxl" fw="bold" c="gray">
            Connections
          </Text>
        </Group>
        <Grid>
          {/* Numeric Metrics */}
          <Grid.Col span={3}>
            <Card shadow="sm" padding="lg" style={{ height: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Text size="sm" align="center" style={{ marginBottom: '10px' }}>Metric 1</Text>
              <Text size="xl" fw="bold" align="center">123</Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={3}>
            <Card shadow="sm" padding="lg" style={{ height: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Text size="sm" align="center" style={{ marginBottom: '10px' }}>Metric 2</Text>
              <Text size="xl" fw="bold" align="center">456</Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={3}>
            <Card shadow="sm" padding="lg" style={{ height: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Text size="sm" align="center" style={{ marginBottom: '10px' }}>Metric 3</Text>
              <Text size="xl" fw="bold" align="center">789</Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={3}>
            <Card shadow="sm" padding="lg" style={{ height: '150px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Text size="sm" align="center" style={{ marginBottom: '10px' }}>Metric 4</Text>
              <Text size="xl" fw="bold" align="center">101</Text>
            </Card>
          </Grid.Col>

          {/* Data Visualization */}
          <Grid.Col span={6}>
            <Card shadow="sm" padding="lg" style={{ height: '250px' }}>
              <Text>Data Viz 1</Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={6}>
            <Card shadow="sm" padding="lg" style={{ height: '250px' }}>
              <Text>Data Viz 2</Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={6}>
            <Card shadow="sm" padding="lg" style={{ height: '250px' }}>
              <Text>Data Viz 3</Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={6}>
            <Card shadow="sm" padding="lg" style={{ height: '250px' }}>
              <Text>Data Viz 4</Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={6}>
            <Card shadow="sm" padding="lg" style={{ height: '250px' }}>
              <Text>Data Viz 5</Text>
            </Card>
          </Grid.Col>
          <Grid.Col span={6}>
            <Card shadow="sm" padding="lg" style={{ height: '250px' }}>
              <Text>Data Viz 6</Text>
            </Card>
          </Grid.Col>
        </Grid>
      </Group>
    </Box>
  )
}
