/**
 * @generated SignedSource<<4abe8a41392161e56cb2e7a09e374287>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RenameDashboardMutationInput = {
  clientMutationId?: string | null;
  metabaseDashboardId: number;
  newName: string;
};
export type dashboardsRenameDashboardMutation$variables = {
  input: RenameDashboardMutationInput;
};
export type dashboardsRenameDashboardMutation$data = {
  readonly renameDashboard: {
    readonly errors: ReadonlyArray<string>;
    readonly metabaseDashboard: {
      readonly id: string;
    } | null;
  } | null;
};
export type dashboardsRenameDashboardMutation = {
  response: dashboardsRenameDashboardMutation$data;
  variables: dashboardsRenameDashboardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RenameDashboardMutationPayload",
    "kind": "LinkedField",
    "name": "renameDashboard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MetabaseDashboard",
        "kind": "LinkedField",
        "name": "metabaseDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "dashboardsRenameDashboardMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "dashboardsRenameDashboardMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9ab7663e09d90b526fc8b47174434601",
    "id": null,
    "metadata": {},
    "name": "dashboardsRenameDashboardMutation",
    "operationKind": "mutation",
    "text": "mutation dashboardsRenameDashboardMutation(\n  $input: RenameDashboardMutationInput!\n) {\n  renameDashboard(input: $input) {\n    metabaseDashboard {\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "19bc23c6c24681c14a8e4c35a33e894f";

export default node;
