/**
 * @generated SignedSource<<7186a07db6dbedc640c608787b1a4dd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ArchiveDashboardMutationInput = {
  clientMutationId?: string | null;
  metabaseDashboardId: number;
};
export type dashboardsArchiveDashboardMutation$variables = {
  input: ArchiveDashboardMutationInput;
};
export type dashboardsArchiveDashboardMutation$data = {
  readonly archiveDashboard: {
    readonly errors: ReadonlyArray<string>;
    readonly metabaseDashboard: {
      readonly id: string;
    } | null;
  } | null;
};
export type dashboardsArchiveDashboardMutation = {
  response: dashboardsArchiveDashboardMutation$data;
  variables: dashboardsArchiveDashboardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ArchiveDashboardMutationPayload",
    "kind": "LinkedField",
    "name": "archiveDashboard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MetabaseDashboard",
        "kind": "LinkedField",
        "name": "metabaseDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "dashboardsArchiveDashboardMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "dashboardsArchiveDashboardMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc2bbecd11e798dc2380882a9a5a1c13",
    "id": null,
    "metadata": {},
    "name": "dashboardsArchiveDashboardMutation",
    "operationKind": "mutation",
    "text": "mutation dashboardsArchiveDashboardMutation(\n  $input: ArchiveDashboardMutationInput!\n) {\n  archiveDashboard(input: $input) {\n    metabaseDashboard {\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "929edc7ab91ab6f926a94496af4b6d9c";

export default node;
