import { Box, Space, Text, rem, Select, Group, ActionIcon } from "@mantine/core"
import styles from "./dashboard-view.module.scss"
import {
  MetabaseProvider,
  StaticDashboard,
  defineMetabaseAuthConfig
} from "@metabase/embedding-sdk-react"
import { useUserStore } from "@shared/store"
import { metabaseTheme } from "@shared/metabase/metabase-theme"
import { useState, useEffect, useMemo } from "react"
import { graphql, useLazyLoadQuery, useQueryLoader } from "react-relay"
import { dashboardViewDashboardsQuery as DashboardViewDashboardsQueryType } from "./__generated__/dashboardViewDashboardsQuery.graphql"
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom"
import { IconArrowLeft } from '@tabler/icons-react'

const DashboardsQuery = graphql`
  query dashboardViewDashboardsQuery {
    getMetabaseDashboards {
    edges{
      node{
        id
        name
				metabaseDashboardId
        archived
      }
      }
    }
  }
`

// @ts-ignore
const originalError = console.error;
console.error = (...args: any) => {
  if (args[0]?.includes('UNSAFE_componentWillReceiveProps') || 
      args[0]?.includes('findDOMNode is deprecated in StrictMode')) {
    return;
  }
  originalError.call(console, ...args);
};

export const DashboardView = () => {
  const { alpyneToken } = useUserStore()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const dashboardIdParam = searchParams.get('dashboardId')
  const [selectedDashboard, setSelectedDashboard] = useState(dashboardIdParam || "")
  const [metabaseError, setMetabaseError] = useState<string | null>(null);

  const data = useLazyLoadQuery(
    DashboardsQuery,
    {}
  )

  const dashboards = useMemo(() => {
    const defaultDashboards = data?.getMetabaseDashboards?.edges
      ?.filter(({ node }) => !node.name.toLowerCase().includes('custom') && !node.archived)
      ?.map(({ node }) => ({
        value: node.id,
        label: node.name,
        metabaseDashboardId: node.metabaseDashboardId
      })) || []

    const customDashboards = data?.getMetabaseDashboards?.edges
      ?.filter(({ node }) => node.name.toLowerCase().includes('custom') && !node.archived)
      ?.map(({ node }) => ({
        value: node.id,
        label: node.name,
        metabaseId: node.metabaseId
      })) || []

    return [
      ...defaultDashboards,
      ...(customDashboards.length > 0 ? [{ value: 'divider', label: '─────────────', disabled: true }] : []),
      ...customDashboards
    ]
  }, [data?.getMetabaseDashboards?.edges])

  const selectedDashboardData = dashboards.find(dashboard => dashboard.value === selectedDashboard)

  const hasDashboards = dashboards.length > 0

  const handleDashboardChange = (value: string) => {
    setSelectedDashboard(value)
    setSearchParams({ dashboardId: value })
  }

  useEffect(() => {
    if (dashboards.length > 0) {
      if (!selectedDashboard) {
        const firstDashboard = dashboards.find(d => d.value !== 'divider')
        if (firstDashboard) {
          handleDashboardChange(firstDashboard.value)
        }
      } else if (!dashboardIdParam) {
        setSearchParams({ dashboardId: selectedDashboard })
      }
    }
  }, [dashboards, selectedDashboard, dashboardIdParam])

  const authConfig = defineMetabaseAuthConfig({
    metabaseInstanceUrl: "https://metabase.getalpyne.com",
    authProviderUri: "/api/metabase/auth",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    }
  })

  const handleMetabaseError = (error: any) => {
    console.error('Metabase Error:', error);
    if (error.status === 0) {
      setMetabaseError('Unable to connect to Metabase server. Please check your connection.');
    } else {
      setMetabaseError('An error occurred while loading the dashboard.');
    }
  };

  if (!alpyneToken) {
    return null
  }

  if (!hasDashboards) {
    return (
      <Box p="xl">
        <Text size="xxl" fw={700} c="gray" component="h1">
          Dashboards
        </Text>
        <Space h={rem(56)} />
        <Text c="dimmed" ta="center">
          You don't have any dashboards configured yet.
        </Text>
      </Box>
    )
  }

  return (
    <Box p="xl">
      <Group justify="space-between" align="center">
        <Group align="center" gap="xs">
          <Link to="/dashboards">
            <ActionIcon
              variant="subtle"
              color="gray"
              aria-label="Back to dashboards"
            >
              <IconArrowLeft size={20} />
            </ActionIcon>
          </Link>
          <Text size="xxl" fw={700} c="gray" component="h1">
            {selectedDashboardData?.label}
          </Text>
        </Group>
        <Select
          data={dashboards}
          value={selectedDashboard}
          onChange={handleDashboardChange}
          placeholder="Select dashboard"
          w={300}
        />
      </Group>
      <Space h={rem(56)} />

      <Box className={styles.TestEmbed}>
        {metabaseError ? (
          <Text color="red" ta="center" p="xl">
            {metabaseError}
          </Text>
        ) : (
          <MetabaseProvider
            authConfig={authConfig}
            theme={metabaseTheme}
            onError={handleMetabaseError}
          >
            <StaticDashboard
              dashboardId={parseInt(selectedDashboardData?.metabaseDashboardId)}
              loadingMessage="Loading dashboard..."
              withTitle={false}
              onError={handleMetabaseError}
            />
          </MetabaseProvider>
        )}
      </Box>
    </Box>
  )
}