import {
  Avatar,
  Badge,
  Flex,
  Group,
  Image,
  Loader,
  Text,
  rem,
} from "@mantine/core"
import { Table } from "@shared/ui/table"
import { ColumnDef } from "@tanstack/react-table"
import { graphql, useFragment, useLazyLoadQuery, useMutation, useQueryLoader } from "react-relay"
import { dashboardsTableDashboardsQuery as DashboardsQueryType } from "./__generated__/dashboardsTableDashboardsQuery.graphql"
import { useNavigate } from "react-router-dom"
import { useEffect, Suspense } from "react"

export const DashboardsQuery = graphql`
  query dashboardsTableDashboardsQuery {
    getMetabaseDashboards {
      edges {
        node {
          id
          name
          metabaseDashboardId
          dashboardType
          creator {
            id
            fullName
          }
          createdAt
          url
          archived
        }
      }
    }
  }
`

const DuplicateDashboardMutation = graphql`
  mutation dashboardsTableDuplicateDashboardMutation($input: DuplicateDashboardMutationInput!) {
    duplicateDashboard(input: $input) {
      metabaseDashboard {
        id
      }
      errors
    }
  }
`

type Dashboard = {
  id: string
  name: string
  creator: string
  dashboardType: string
  createdAt: string
  url: string | null
  metabaseDashboardId: number
}

type Props = {
  columns: ColumnDef<Dashboard>[]
}

export const DashboardsTable = ({ columns }: Props) => {
  const [queryRef, loadQuery] = useQueryLoader<DashboardsQueryType>(DashboardsQuery)

  // Initialize the query
  useEffect(() => {
    loadQuery({})
  }, [loadQuery])

  const refetch = () => {
    loadQuery({}, { fetchPolicy: 'network-only' })
  }

  // Use suspense to handle loading state
  return (
    <Suspense fallback={<Loader />}>
      <DashboardsTableContent 
        columns={columns} 
        queryRef={queryRef}
        refetch={refetch}
      />
    </Suspense>
  )
}

// Separate component to handle the actual data fetching
const DashboardsTableContent = ({ 
  columns, 
  queryRef,
  refetch 
}: Props & { 
  queryRef: any,
  refetch: () => void 
}) => {
  const data = useLazyLoadQuery<DashboardsQueryType>(DashboardsQuery, {}, {
    fetchPolicy: 'network-only'
  })

  const handleDuplicate = (metabaseDashboardId: number, refetch?: () => void) => {
    duplicateDashboard({
      variables: {
        input: {
          metabaseDashboardId
        }
      },
      onCompleted: (response, errors) => {
        if (errors) {
          return
        }
        if (response.duplicateDashboard.errors?.length > 0) {
          return
        }
        if (typeof refetch === 'function') {
          refetch()
        }
      },
      onError: (error) => {
        // Keep this error handler empty or implement proper error handling
      }
    })
  }

  const tableData = data.getMetabaseDashboards.edges
    .filter(edge => !edge.node.archived)
    .map(edge => ({
      name: edge.node.name,
      id: edge.node.id,
      creator: edge.node.creator.fullName,
      dashboardType: edge.node.dashboardType,
      createdAt: new Date(edge.node.createdAt).toLocaleDateString(),
      url: edge.node.url,
      href: `/dashboard-view?dashboardId=${edge.node.id}`,
      target: "_blank",
      metabaseDashboardId: edge.node.metabaseDashboardId
    }))
    .sort((a, b) => {
      const typeCompare = b.dashboardType.localeCompare(a.dashboardType);
      return typeCompare !== 0 ? typeCompare : a.name.localeCompare(b.name);
    })

  return (
    <Table
      columns={columns}
      data={tableData}
      meta={{
        refetch
      }}
    />
  )
} 