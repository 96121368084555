/**
 * @generated SignedSource<<a0d67c499198bc96493eae7cb3a31ddc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MetabaseDashboardTypeEnum = "CUSTOM" | "DEFAULT";
export type dashboardsTableDashboardsQuery$variables = {};
export type dashboardsTableDashboardsQuery$data = {
  readonly getMetabaseDashboards: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly archived: boolean;
        readonly createdAt: string;
        readonly creator: {
          readonly fullName: string | null;
          readonly id: string;
        };
        readonly dashboardType: MetabaseDashboardTypeEnum;
        readonly id: string;
        readonly metabaseDashboardId: number | null;
        readonly name: string;
        readonly url: string;
      };
    }>;
  };
};
export type dashboardsTableDashboardsQuery = {
  response: dashboardsTableDashboardsQuery$data;
  variables: dashboardsTableDashboardsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MetabaseDashboardConnection",
    "kind": "LinkedField",
    "name": "getMetabaseDashboards",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MetabaseDashboardEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MetabaseDashboard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metabaseDashboardId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dashboardType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archived",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dashboardsTableDashboardsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dashboardsTableDashboardsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "11a1023e5256779b4063d9f56225aab7",
    "id": null,
    "metadata": {},
    "name": "dashboardsTableDashboardsQuery",
    "operationKind": "query",
    "text": "query dashboardsTableDashboardsQuery {\n  getMetabaseDashboards {\n    edges {\n      node {\n        id\n        name\n        metabaseDashboardId\n        dashboardType\n        creator {\n          id\n          fullName\n        }\n        createdAt\n        url\n        archived\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f51f8b472a5f4ed03c5fb756e1b9fa4c";

export default node;
