import styles from "./table.module.scss"
import {
  ActionIcon,
  Flex,
  Group,
  MantineStyleProp,
  Table as MantineTable,
  Text,
  rem,
} from "@mantine/core"
import { themeVars } from "@shared/theme"
import {
  IconArrowDown,
  IconArrowUp,
  IconArrowsSort,
  IconCaretDownFilled,
  IconCaretRightFilled,
} from "@tabler/icons-react"
import {
  ColumnDef,
  SortingState,
  defaultColumnSizing,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { Fragment, useEffect, useState } from "react"

type Props<T, U> = {
  data: T[]
  columns: ColumnDef<T>[]
  style?: MantineStyleProp
  subColumns?: ColumnDef<U>[]
  searchQuery?: string
  parentRowId?: string
  autoColumnsWidth?: boolean
  compact?: boolean
  onRowClick?: (row: T) => void
  highlightOnHover?: boolean
  fixedLayout?: boolean
  withPagination?: boolean
}

export function Table<T, U>({
  data,
  columns,
  subColumns,
  searchQuery = "",
  onRowClick,
  autoColumnsWidth,
  parentRowId,
  style,
  compact = false,
  highlightOnHover = false,
  fixedLayout = false,
  withPagination = false,
}: Props<T, U>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = useState(searchQuery)

  useEffect(() => {
    setGlobalFilter(searchQuery)
  }, [searchQuery])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: "auto",
    getSubRows: (row: T) => {
      return (row as T & { subRows: T[] }).subRows
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })
  const headerWidth = autoColumnsWidth ? `${100 / columns.length}%` : "auto"
  return (
    <MantineTable
      classNames={{
        table: [
          styles.Table,
          fixedLayout && styles["Table--fixed"],
          withPagination && styles["Table--withPagination"],
        ].join(" "),
        th: [styles.Th, compact && styles["Th--compact"]].join(" "),
        td: [styles.Td, compact && styles["Td--compact"]].join(" "),
      }}
      style={style}
      highlightOnHover={highlightOnHover}
      highlightOnHoverColor="gray.0"
    >
      <MantineTable.Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <MantineTable.Tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => {
              const ThWidth =
                header.getSize() == defaultColumnSizing.size
                  ? headerWidth
                  : header.getSize()
              return (
                <MantineTable.Th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  w={ThWidth}
                  className={[
                    header.column.getCanSort() ? styles["Th--sortable"] : "",
                    parentRowId && index === 0 ? styles["Th--sub_header"] : "",
                    header.column.columnDef.meta?.keepCasing
                      ? ""
                      : styles["Th--capitalize"],
                  ].join(" ")}
                  align={header.column.columnDef.meta?.align}
                >
                  {header.isPlaceholder ? null : (
                    <Group
                      gap="0.5rem"
                      justify={
                        header.column.columnDef.meta &&
                        header.column.columnDef.meta.align === "right"
                          ? "flex-end"
                          : "flex-start"
                      }
                    >
                      {header.column.columnDef.header !== "" && (
                        <Text size="sm" fw="bold">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Text>
                      )}

                      {header.column.getCanSort() &&
                        ({
                          asc: <IconArrowUp size={16} />,
                          desc: <IconArrowDown size={16} />,
                        }[header.column.getIsSorted() as string] ?? (
                          <IconArrowsSort size={16} />
                        ))}
                    </Group>
                  )}
                </MantineTable.Th>
              )
            })}
          </MantineTable.Tr>
        ))}
      </MantineTable.Thead>
      <MantineTable.Tbody>
        {table.getRowModel().rows.map((row) => (
          <Fragment key={row.id}>
            <MantineTable.Tr
              key={row.id}
              style={{ borderBottom: row.getIsExpanded() ? "none" : undefined }}
            >
              {row.getVisibleCells().map((cell, index) => (
                <MantineTable.Td
                  align={
                    cell.column.columnDef.meta?.cellAlign ??
                    cell.column.columnDef.meta?.align ??
                    "left"
                  }
                  key={cell.id}
                  onClick={() =>
                    !cell.column.columnDef.meta?.disableRowClick &&
                    onRowClick?.(row.original)
                  }
                  className={[
                    onRowClick && !cell.column.columnDef.meta?.disableRowClick
                      ? styles["Td--clickable"]
                      : "",
                    cell.column.columnDef.meta?.className,
                    parentRowId && index === 0 ? styles["Td--sub_row"] : "",
                  ].join(" ")}
                >
                  <Flex
                    gap={rem(8)}
                    align="center"
                    justify={
                      cell.column.columnDef.meta?.align === "right"
                        ? "end"
                        : undefined
                    }
                  >
                    {row.subRows.length > 0 && index === 0 && (
                      <ActionIcon
                        variant="transparent"
                        c="gray"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation()
                          row.toggleExpanded()
                        }}
                      >
                        {row.getIsExpanded() ? (
                          <IconCaretDownFilled size={16} />
                        ) : (
                          <IconCaretRightFilled size={16} />
                        )}
                      </ActionIcon>
                    )}
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Flex>
                </MantineTable.Td>
              ))}
            </MantineTable.Tr>
            {row.getIsExpanded() && row.depth <= 1 && subColumns && (
              <MantineTable.Tr p={0}>
                <MantineTable.Td colSpan={columns.length} p={rem(8)}>
                  <Table
                    columns={subColumns as unknown as ColumnDef<T>[]}
                    subColumns={subColumns}
                    searchQuery={searchQuery}
                    parentRowId={row.id}
                    autoColumnsWidth={!!autoColumnsWidth}
                    style={{
                      backgroundColor: themeVars.colors.gray[0],
                      outline: `0.0625rem solid ${themeVars.colors.gray[2]}`,
                      boxShadow: "none",
                      borderRadius: rem(8),
                    }}
                    data={row.subRows.map((subRow) => subRow.original)}
                    compact={compact}
                  />
                </MantineTable.Td>
              </MantineTable.Tr>
            )}
          </Fragment>
        ))}
      </MantineTable.Tbody>
    </MantineTable>
  )
}
