import { defineMetabaseTheme } from "@metabase/embedding-sdk-react"

export const metabaseTheme = defineMetabaseTheme({
  fontFamily: "Ubuntu",
  colors: {
    brand: "#000000",
    "text-primary": "#6b7280",
    "text-secondary": "#9ca3af",
    "text-tertiary": "#d1d5db"
  }
}) 