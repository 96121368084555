/**
 * @generated SignedSource<<2e48ebaecc29ffcb4092e9378398c0e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type dashboardViewDashboardsQuery$variables = {};
export type dashboardViewDashboardsQuery$data = {
  readonly getMetabaseDashboards: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly archived: boolean;
        readonly id: string;
        readonly metabaseDashboardId: number | null;
        readonly name: string;
      };
    }>;
  };
};
export type dashboardViewDashboardsQuery = {
  response: dashboardViewDashboardsQuery$data;
  variables: dashboardViewDashboardsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MetabaseDashboardConnection",
    "kind": "LinkedField",
    "name": "getMetabaseDashboards",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MetabaseDashboardEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MetabaseDashboard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metabaseDashboardId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archived",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dashboardViewDashboardsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dashboardViewDashboardsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "275299b5c40ab88f823b2020800deaa8",
    "id": null,
    "metadata": {},
    "name": "dashboardViewDashboardsQuery",
    "operationKind": "query",
    "text": "query dashboardViewDashboardsQuery {\n  getMetabaseDashboards {\n    edges {\n      node {\n        id\n        name\n        metabaseDashboardId\n        archived\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee9f93113ea871a5e67a75ab45bcdda0";

export default node;
