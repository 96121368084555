/**
 * @generated SignedSource<<5ec8df17361f090b896416ff51fba650>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeCurrentClientMutationInput = {
  clientId: string;
  clientMutationId?: string | null;
};
export type dashboardsChangeCurrentClientMutation$variables = {
  input: ChangeCurrentClientMutationInput;
};
export type dashboardsChangeCurrentClientMutation$data = {
  readonly changeCurrentClient: {
    readonly errors: ReadonlyArray<string>;
    readonly user: {
      readonly currentClient: {
        readonly id: string;
      } | null;
      readonly id: string;
    } | null;
  } | null;
};
export type dashboardsChangeCurrentClientMutation = {
  response: dashboardsChangeCurrentClientMutation$data;
  variables: dashboardsChangeCurrentClientMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeCurrentClientMutationPayload",
    "kind": "LinkedField",
    "name": "changeCurrentClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Client",
            "kind": "LinkedField",
            "name": "currentClient",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "dashboardsChangeCurrentClientMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "dashboardsChangeCurrentClientMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d3d4d7d155bc11df815bb260af377411",
    "id": null,
    "metadata": {},
    "name": "dashboardsChangeCurrentClientMutation",
    "operationKind": "mutation",
    "text": "mutation dashboardsChangeCurrentClientMutation(\n  $input: ChangeCurrentClientMutationInput!\n) {\n  changeCurrentClient(input: $input) {\n    user {\n      id\n      currentClient {\n        id\n      }\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd2157ab11318f339e01b81fb87b506e";

export default node;
