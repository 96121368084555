import { clientSelectorClientMutation as ClientSelectorClientMutationType } from "./__generated__/clientSelectorClientMutation.graphql"
import { clientSelectorClientsFragment$key } from "./__generated__/clientSelectorClientsFragment.graphql"
import styles from "./client-selector.module.scss"
import { Avatar, Box, Menu, Text, rem, LoadingOverlay } from "@mantine/core"
import { updateCurrentMetabaseTokenCache } from "@shared/relay/utils/cache"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { Combobox, ComboxboxDataType } from "@shared/ui/combobox"
import { IconCheck } from "@tabler/icons-react"
import { useMemo, useState } from "react"
import { graphql, useFragment, useMutation } from "react-relay"
import { useLocation, useNavigate } from "react-router-dom"
import { pathConstants } from "frontend/routes/path-constants"

const ClientSelectorChangeClientMutation = graphql`
  mutation clientSelectorClientMutation(
    $input: ChangeCurrentClientMutationInput!
  ) {
    changeCurrentClient(input: $input) {
      errors
      metabaseJwtToken
      user {
        ...navbarUserFragment
        currentClient {
          id
          name
          metabaseDefaultDashboardId
        }
      }
    }
  }
`

const ClientSelectorClientsFragment = graphql`
  fragment clientSelectorClientsFragment on User
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    clients(first: $count, after: $cursor)
      @connection(key: "ClientSelectorClientsFragment_clients") {
      edges {
        node {
          id
          name
          slug
          logo
          color
        }
      }
    }
  }
`

type Props = {
  clients: clientSelectorClientsFragment$key
  navbarCollapsed: boolean
  open: boolean
  setOpen: (val: boolean) => void
}

export const ClientSelector = ({
  clients,
  navbarCollapsed,
  open,
  setOpen,
}: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    clients: { edges },
  } = useFragment(ClientSelectorClientsFragment, clients)
  const [changeCurrentClient, changeCurrentClientLoading] =
    useMutation<ClientSelectorClientMutationType>(
      ClientSelectorChangeClientMutation
    )
  const { currentClient } = useUserStore()
  const [isTransitioning, setIsTransitioning] = useState(false)

  const userClients: ComboxboxDataType[] = useMemo(
    () =>
      edges.map((client) => ({
        label: client.node.name,
        value: client.node.id,
        imageSrc: client.node.logo ?? "",
        icon: (
          <Avatar
            src={client.node.logo ?? null}
            size="xs"
            color={client.node.color ?? "lightBlue"}
            variant="filled"
          >
            <Text size={rem(10)}>{client.node.name[0]}</Text>
          </Avatar>
        ), // Replace this when we have client images
      })),
    [edges]
  )

  const menuItemClients = useMemo(
    () =>
      edges.map((client) => {
        const isSelected = currentClient.id == client.node.id

        return (
          <Menu.Item
            classNames={{
              item: isSelected
                ? styles.MenuItem__Selected
                : styles.MenuItem__Default,
              itemLabel: isSelected
                ? styles.MenuItem__Selected_itemLabel
                : styles.MenuItem__Default_itemLabel,
            }}
            key={client.node.id}
            leftSection={
              <Avatar
                src={client.node.logo}
                size="xs"
                color={client.node.color ?? "lightBlue"}
                variant="filled"
              >
                <Text size={rem(10)}>{client.node.name[0]}</Text>
              </Avatar>
            }
            onClick={() => {
              handleChange(client.node.id)
            }}
            {...(currentClient.id == client.node.id && {
              rightSection: (
                <IconCheck size={12} color={themeVars.colors.blue[7]} />
              ),
            })}
          >
            {client.node.name}
          </Menu.Item>
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentClient.id, edges]
  )

  const hasClients = userClients.length > 0

  const handleChange = (client_id: string) => {
    // Set transitioning state immediately
    setIsTransitioning(true)
    
    changeCurrentClient({
      variables: {
        input: {
          clientId: client_id,
        },
      },
      updater: (store, data) => {
        const newToken = data.changeCurrentClient?.metabaseJwtToken
        updateCurrentMetabaseTokenCache(store, data, newToken)
        
        // First update the URL without triggering a navigation event
        window.history.replaceState({}, '', pathConstants.HOME)
        
        // Then do a full reload
        window.location.reload()
      },
    })
  }

  return (
    <Menu
      position="right-start"
      disabled={isTransitioning || !navbarCollapsed || !hasClients || changeCurrentClientLoading}
      opened={open}
      onChange={setOpen}
    >
      <Menu.Target>
        <Box>
          <Combobox
            defaultValue={
              userClients.length === 1 ? userClients[0].value : currentClient.id
            }
            data={userClients}
            onChange={handleChange}
            disabled={isTransitioning || !hasClients || changeCurrentClientLoading}
            compact={navbarCollapsed}
          />
        </Box>
      </Menu.Target>
      <Menu.Dropdown ml={8} miw={180}>
        {menuItemClients}
      </Menu.Dropdown>
    </Menu>
  )
}
