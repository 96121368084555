import {
  Box,
  Group,
  Input,
  LoadingOverlay,
  Space,
  Text,
  rem,
  ActionIcon,
  Menu,
  Modal,
  Select,
  Button,
} from "@mantine/core"
import { useUserStore } from "@shared/store"
import { IconSearch, IconDotsVertical } from "@tabler/icons-react"
import { Suspense, useEffect, useState } from "react"
import { graphql, useLazyLoadQuery, useQueryLoader, useMutation } from "react-relay"
import { useSearchParams } from "react-router-dom"
import styles from "./dashboards.module.scss"
import { ColumnDef } from "@tanstack/react-table"
import { DashboardsTable } from "./dashboards-table"
import { DashboardsQuery } from './dashboards-table/dashboards-table'
import type { dashboardsTableDashboardsQuery as DashboardsQueryType } from './dashboards-table/__generated__/dashboardsTableDashboardsQuery.graphql'

type Dashboard = {
  id: string
  name: string
  creator: string
  dashboardType: string
  createdAt: string
  url: string | null
  href: string
  target?: string
  metabaseDashboardId: number
}

const DuplicateDashboardMutation = graphql`
  mutation dashboardsDuplicateDashboardMutation($input: DuplicateDashboardMutationInput!) {
    duplicateDashboard(input: $input) {
      metabaseDashboard {
        id
      }
      errors
    }
  }
`

const ArchiveDashboardMutation = graphql`
  mutation dashboardsArchiveDashboardMutation($input: ArchiveDashboardMutationInput!) {
    archiveDashboard(input: $input) {
      metabaseDashboard {
        id
      }
      errors
    }
  }
`

const ChangeCurrentClientMutation = graphql`
  mutation dashboardsChangeCurrentClientMutation($input: ChangeCurrentClientMutationInput!) {
    changeCurrentClient(input: $input) {
      user {
        id
        currentClient {
          id
        }
      }
      errors
    }
  }
`

const RenameDashboardMutation = graphql`
  mutation dashboardsRenameDashboardMutation(
    $input: RenameDashboardMutationInput!
  ) {
    renameDashboard(
      input: $input
    ) {
      metabaseDashboard {
        id
      }
      errors
    }
  }
`

const ClientsQuery = graphql`
  query dashboardsClientsQuery {
    getClients {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const Dashboards = () => {
  const [duplicateDashboard] = useMutation(DuplicateDashboardMutation)
  const [archiveDashboard] = useMutation(ArchiveDashboardMutation)
  const [renameDashboard] = useMutation(RenameDashboardMutation)
  const [changeCurrentClient] = useMutation(ChangeCurrentClientMutation)
  const [queryRef, loadQuery] = useQueryLoader<DashboardsQueryType>(DashboardsQuery)
  const [copyModalOpen, setCopyModalOpen] = useState(false)
  const [selectedDashboardId, setSelectedDashboardId] = useState<number | null>(null)
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null)
  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [newDashboardName, setNewDashboardName] = useState("")

  const clientsData = useLazyLoadQuery(ClientsQuery, {})

  const clientOptions = clientsData.getClients.edges.map(({ node }) => ({
    value: node.id,
    label: node.name
  }))

  const handleDuplicate = (metabaseDashboardId: number, refetch?: () => void) => {
    duplicateDashboard({
      variables: {
        input: {
          metabaseDashboardId
        }
      },
      onCompleted: (response, errors) => {
        if (errors) {
          return
        }
        if (response.duplicateDashboard.errors?.length > 0) {
          return
        }
        loadQuery({}, { fetchPolicy: 'network-only' })
      },
      onError: (error) => {
        // Keep this error handler empty or implement proper error handling
      }
    })
  }

  const handleArchive = (metabaseDashboardId: number) => {
    archiveDashboard({
      variables: {
        input: {
          metabaseDashboardId
        }
      },
      onCompleted: (response, errors) => {
        if (errors) {
          return
        }
        if (response.archiveDashboard.errors?.length > 0) {
          return
        }
        loadQuery({}, { fetchPolicy: 'network-only' })
      },
      onError: (error) => {
        // Keep this error handler empty or implement proper error handling
      }
    })
  }

  const handleDuplicateToClient = () => {
    if (!selectedDashboardId || !selectedClientId) return

    duplicateDashboard({
      variables: {
        input: {
          metabaseDashboardId: selectedDashboardId,
          targetClientId: selectedClientId
        }
      },
      onCompleted: (response, errors) => {
        if (errors) {
          return
        }
        if (response.duplicateDashboard.errors?.length > 0) {
          return
        }
        setCopyModalOpen(false)
        setSelectedClientId(null)
        loadQuery({}, { fetchPolicy: 'network-only' })
      },
      onError: (error) => {
        // Keep this error handler empty or implement proper error handling
      }
    })
  }

  const handleRename = () => {
    if (!selectedDashboardId || !newDashboardName) return

    renameDashboard({
      variables: {
        input: {
          metabaseDashboardId: selectedDashboardId,
          newName: newDashboardName
        }
      },
      onCompleted: (response, errors) => {
        if (errors) {
          return
        }
        if (response.renameDashboard.errors?.length > 0) {
          return
        }
        setRenameModalOpen(false)
        setNewDashboardName("")
        loadQuery({}, { fetchPolicy: 'network-only' })
      },
      onError: (error) => {
        // Keep this error handler empty or implement proper error handling
      }
    })
  }

  const columns: ColumnDef<Dashboard>[] = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (info) => (
        <Text>{info.getValue() as string}</Text>
      ),
    },
    {
      header: "Type",
      accessorKey: "dashboardType",
    },
    {
      header: "Owner",
      accessorKey: "creator",
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
    },
    {
      id: "actions",
      header: "",
      cell: (info) => (
        <Menu position="bottom-end" withinPortal>
          <Menu.Target>
            <ActionIcon variant="subtle" color="gray">
              <IconDotsVertical style={{ width: '1rem', height: '1rem' }} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              component="a"
              href={info.row.original.href}
              target={info.row.original.target}
            >
              View
            </Menu.Item>
            <Menu.Item
              onClick={() => handleDuplicate(info.row.original.metabaseDashboardId)}
            >
              Duplicate
            </Menu.Item>
            <Menu.Item
              component="a"
              href={info.row.original.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Public Link
            </Menu.Item>
            {info.row.original.dashboardType === "CUSTOM" && (
              <>
                <Menu.Item
                  onClick={() => {
                    setSelectedDashboardId(info.row.original.metabaseDashboardId)
                    setNewDashboardName(info.row.original.name)
                    setRenameModalOpen(true)
                  }}
                >
                  Rename
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setSelectedDashboardId(info.row.original.metabaseDashboardId)
                    setCopyModalOpen(true)
                  }}
                >
                  Copy to Different Client
                </Menu.Item>
                <Menu.Item
                  onClick={() => handleArchive(info.row.original.metabaseDashboardId)}
                  color="red"
                >
                  Archive
                </Menu.Item>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      ),
    },
  ]

  return (
    <Box className={styles.Dashboards} pos="relative">
      <Group justify="space-between">
        <Text size="xxl" fw="bold" c="gray">
          Dashboards
        </Text>
      </Group>

      <Box>
        <Space h={rem(56)} />
        <DashboardsTable
          columns={columns}
        />
      </Box>

      <Modal
        opened={copyModalOpen}
        onClose={() => {
          setCopyModalOpen(false)
          setSelectedClientId(null)
        }}
        title="Copy Dashboard to Different Client"
      >
        <Select
          label="Select Client"
          placeholder="Choose a client"
          value={selectedClientId}
          onChange={setSelectedClientId}
          data={clientOptions}
          searchable
          required
        />
        <Group justify="flex-end" mt="md">
          <Button
            onClick={handleDuplicateToClient}
            disabled={!selectedClientId}
          >
            Copy Dashboard
          </Button>
        </Group>
      </Modal>

      <Modal
        opened={renameModalOpen}
        onClose={() => {
          setRenameModalOpen(false)
          setNewDashboardName("")
        }}
        title="Rename Dashboard"
      >
        <Input
          label="New Dashboard Name"
          placeholder="Enter new name"
          value={newDashboardName}
          onChange={(e) => setNewDashboardName(e.currentTarget.value)}
          required
        />
        <Group justify="flex-end" mt="md">
          <Button
            onClick={handleRename}
            disabled={!newDashboardName}
          >
            Rename Dashboard
          </Button>
        </Group>
      </Modal>

    </Box>
  )
}
