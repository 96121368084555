import { appLayoutGetCurrentUserDataQuery as AppLayoutGetCurrentUserDataQueryType } from "./__generated__/appLayoutGetCurrentUserDataQuery.graphql"
import { Navbar } from "./navbar"
import { StopImpersonateButton } from "./stop-impersonate-button"
import { AppShell, Group, Text } from "@mantine/core"
import { useUserStore } from "@shared/store"
import { State } from "@shared/store/store"
import { ServerError } from "@shared/ui/errors/server-error"
import { IconSpy } from "@tabler/icons-react"
import { Suspense, useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { graphql, useLazyLoadQuery } from "react-relay"
import { Outlet, useLocation } from "react-router-dom"

const AppLayoutGetCurrentUserDataQuery = graphql`
  query appLayoutGetCurrentUserDataQuery {
    getCurrentUserData {
      errors
      user {
        id
        isFirmAdmin
        isSuperAdmin
        ...navbarUserFragment
        currentClient {
          id
          name
          slug
          metabaseDefaultDashboardId
        }
        fullName
        firm {
          name
        }
      }
      metabaseJwtToken
      features
      permissions
    }
  }
`

export const AppLayout = () => {
  const location = useLocation()
  const {
    getCurrentUserData: { user, metabaseJwtToken, features, permissions },
  } = useLazyLoadQuery<AppLayoutGetCurrentUserDataQueryType>(
    AppLayoutGetCurrentUserDataQuery,
    {}
  )

  const {
    navbarCollapsed,
    impersonatorUser,
    setMetabaseToken,
    setCurrentClientInfo,
    setUserInfo,
    setFeatureFlags,
  } = useUserStore()

  useEffect(() => {
    const { currentClient, id: userID, isFirmAdmin, isSuperAdmin } = user

    const dashboardId = currentClient?.metabaseDefaultDashboardId
    const clientId = currentClient?.id
    const name = currentClient?.name
    const slug = currentClient?.slug

    if (dashboardId && clientId && name && slug) {
      setCurrentClientInfo(clientId, name, slug, dashboardId)
    }

    if (typeof metabaseJwtToken === "string") {
      setMetabaseToken(metabaseJwtToken)
    }

    if (userID) {
      setUserInfo({
        isAdmin: isFirmAdmin,
        isSuperAdmin: isSuperAdmin,
        userId: userID,
      })

      setFeatureFlags(features, permissions as State["permissions"])
    }
  }, [
    user,
    metabaseJwtToken,
    features,
    permissions,
    setMetabaseToken,
    setCurrentClientInfo,
    setUserInfo,
    setFeatureFlags,
  ])
  const headerProps = impersonatorUser.id
    ? { header: { height: "3.5rem" } }
    : {}
  return (
    <AppShell
      {...headerProps}
      navbar={{
        width: navbarCollapsed ? "4.25rem" : "15.75rem",
        breakpoint: "xs",
      }}
      padding="0"
    >
      {impersonatorUser.id && (
        <AppShell.Header bg="red.6" px="1.5rem" py="0.75rem">
          <Group justify="space-between">
            <Group h="100%" gap="0.5rem">
              <IconSpy size={16} color="white" />
              <Text size="sm" c="white">
                Impersonating{" "}
                <Text component="span" fw={700}>
                  {user.fullName}
                </Text>{" "}
                from{" "}
                <Text component="span" fw={700}>
                  {user.firm.name}
                </Text>
                . Role:{" "}
                <Text component="span" fw={700}>
                  {user.isFirmAdmin ? "Admin" : "Member"}
                </Text>
              </Text>
            </Group>
            <StopImpersonateButton />
          </Group>
        </AppShell.Header>
      )}
      {user && (
        <Navbar 
          user={user} 
          impersonatorUser={impersonatorUser}
        />
      )}
      <AppShell.Main>
        <ErrorBoundary
          key={location.pathname}
          fallbackRender={(error) => <ServerError error={error} />}
        >
          <Suspense>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </AppShell.Main>
    </AppShell>
  )
}
