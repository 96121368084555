/**
 * @generated SignedSource<<5e6085e105760b177f14532b3148508e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type sharedDashboardQuery$variables = {
  uuid: string;
};
export type sharedDashboardQuery$data = {
  readonly getSharedDashboard: {
    readonly errors: ReadonlyArray<string> | null;
    readonly metabaseDashboard: {
      readonly company: {
        readonly name: string;
      };
      readonly id: string;
      readonly metabaseDashboardId: number | null;
      readonly name: string;
    } | null;
    readonly metabaseJwtToken: string | null;
  };
};
export type sharedDashboardQuery = {
  response: sharedDashboardQuery$data;
  variables: sharedDashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metabaseDashboardId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metabaseJwtToken",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sharedDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SharedDashboardResponse",
        "kind": "LinkedField",
        "name": "getSharedDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MetabaseDashboard",
            "kind": "LinkedField",
            "name": "metabaseDashboard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sharedDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SharedDashboardResponse",
        "kind": "LinkedField",
        "name": "getSharedDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MetabaseDashboard",
            "kind": "LinkedField",
            "name": "metabaseDashboard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5784c37a2a19526e7b1bee2b18bae8b",
    "id": null,
    "metadata": {},
    "name": "sharedDashboardQuery",
    "operationKind": "query",
    "text": "query sharedDashboardQuery(\n  $uuid: String!\n) {\n  getSharedDashboard(uuid: $uuid) {\n    metabaseDashboard {\n      id\n      name\n      metabaseDashboardId\n      company {\n        name\n        id\n      }\n    }\n    metabaseJwtToken\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "be744726c57d7f407012bcecb7e09738";

export default node;
